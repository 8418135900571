import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"


import artworks from "../images/artworks.png"
import audioWorks from "../images/audio-works.png"
import communicationCollaterals from "../images/communication-collaterals.png"
import videoWorks from "../images/video-works.png"
import servicesImg from "../images/services-img.jpg"

import { IconContext } from "react-icons"
import { FaChevronRight } from "react-icons/fa"

import servicesStyles from "../styles/pages/services.module.css"

const ServicesPage = () => (
  <Layout location="services">
    <SEO title="Services" />
    <div className={ servicesStyles.headerSection }>
      <div className={ servicesStyles.services }>
        <h1>Services</h1>
        <div className={ servicesStyles.service }>
          <img src={ artworks } />
          <div className={ servicesStyles.serviceText}>
            <h3>Artworks for company literature</h3>
            <p>Company profiles, special publications, posters, brochures, leaflets, flyers</p>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <img src={ communicationCollaterals } />
          <div className={ servicesStyles.serviceText}>
            <h3>Communication collaterals</h3>
            <p>Banners, Streamers POPS, and other related items</p>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <img src={ videoWorks } />
          <div className={ servicesStyles.serviceText}>
            <h3>Video works / AVPs</h3>
            <p>Video coverages, moving slides, screen savers to logo animations</p>
          </div>
        </div>
        <div className={ servicesStyles.service }>
          <img src={ audioWorks } />
          <div className={ servicesStyles.serviceText}>
            <h3>Audio works</h3>
            <p>Outputs include ambient soundscape, shoestring radio broadcast, commercials, jingles, dubs and voice-over, and other audio recordings</p>
          </div>
        </div>
        <div className={ servicesStyles.galleryLink }>
          <Link to="../gallery/">Check out samples</Link>
          <IconContext.Provider value={{ className: servicesStyles.linkIcon }}>
            <FaChevronRight />
          </IconContext.Provider>
        </div>
      </div>
      <div className={ servicesStyles.servicesImg }>
        <img src={ servicesImg } alt=""/>
      </div>
    </div>
  </Layout>
)

export default ServicesPage